import React from 'react';
import pageStyle from '../../assets/css/page.module.css';
import style from '../../assets/css/main.module.css';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import HeroPage from '../../components/hero.page';
import Hamburger from '../../components/hamburger';
import { PricingTable, PricingSlot, PricingDetail } from 'react-pricing-table';

import imgSEO from '../../assets/img/services/graphicdesign.jpg';

import SimilarCard from '../../components/similar.card';

import similar1 from '../../assets/img/services/thumbnail/copywriting.jpg';
import similar2 from '../../assets/img/services/thumbnail/website.jpg';
import similar3 from '../../assets/img/services/thumbnail/dev-mobile.jpg';
import similar4 from '../../assets/img/services/thumbnail/developpement.jpg';
import SEO from '../../components/seo';
import CtaSection from '../../components/cta.section';
import cta from '../../assets/img/illustrations/design.svg';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Réseaux sociaux | Agence digitale | MDS Digital Agency"
      description="Votre graphic design est un élément capital. Cerner le prisme de l’identité de votre marque nous permet de vous offrir une charte graphique personnalisée."
      keywords="graphic design"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2>Social branding</h2>
      <p>Et si vous faisiez des réseaux sociaux votre outil de communication ?</p>

      <p>
        Aller sur les réseaux sociaux est l’une des premières choses que l’on fait le matin, être
        présent sur les réseaux sociaux est donc indispensable pour une entreprise qui veut être
        proche de ses consommateurs. Le social branding est un moyen de communication efficace
        permettant à une entreprise de partager ses idées et d’ancrer son image de marque dans la
        tête du consommateur.
      </p>
      <p>
        MDS Digital Agency peut vous aider à développer votre image de marque sur les réseaux
        sociaux en vous proposant un service complet adapté à vos besoins.
      </p>
      <PricingTable highlightColor="#fba515">
        <PricingSlot buttonText="NOUS CONTACTER" title="Pack 1">
          <PricingDetail>
            <b>1</b> réseau social
          </PricingDetail>
          <PricingDetail>
            <b>1</b> publication/semaine
          </PricingDetail>
          <PricingDetail>
            {' '}
            Gestion des <b>commentaires</b> et <b>messages privés</b> une fois par semaine{' '}
          </PricingDetail>
        </PricingSlot>
        <PricingSlot highlighted buttonText="NOUS CONTACTER" title="Pack 2">
          <PricingDetail>
            <b>2</b> réseaux sociaux
          </PricingDetail>
          <PricingDetail>
            <b>2</b> publications/semaine
          </PricingDetail>
          <PricingDetail>
            {' '}
            Gestion des <b>commentaires</b> et <b>messages privés</b> une fois par semaine{' '}
          </PricingDetail>
        </PricingSlot>
        <PricingSlot buttonText="NOUS CONTACTER" title="Pack 3">
          <PricingDetail>
            <b>3</b> réseaux sociaux
          </PricingDetail>
          <PricingDetail>
            <b>3</b> publications/semaine
          </PricingDetail>
          <PricingDetail>
            {' '}
            Gestion des <b>commentaires</b> et <b>messages privés</b> une fois par semaine{' '}
          </PricingDetail>
        </PricingSlot>
      </PricingTable>
      <div>
        <div />
        <div />
      </div>
      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="Je veux me différencier de mes concurrents. Je suis à la recherche de supports de communication qui reflètent mon expérience, mes valeurs et mes missions. Pouvez-vous m’aider?"
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard text="Copywriting" url="/services/copywriting/" img={similar1} />
          <SimilarCard text="Création de sites internet" url="/services/website/" img={similar2} />
          <SimilarCard
            text="Développement mobile"
            url="/services/developpement-mobile/"
            img={similar3}
          />
          <SimilarCard text="Développement" url="/services/developpement/" img={similar4} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
